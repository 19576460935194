import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getNewAccessToken, LoggedInUser, Modules, SubModule } from "../App";
import Access from "./Access";
import AccessSub from "./AccessSub";
import { SelectedValue } from "./User";

const Initial = () => {
  const navigate = useNavigate();
  const { modules, setModules } = useContext(Modules);
  const [activeModule, setActiveModule] = useState();
  const { selectedValue, setSelectedValue } = useContext(SelectedValue);
  // const [selectedValue, setSelectedValue] = useState([]);
  const WeHearDocumantation1 = localStorage.getItem("WeHearDocumantation1");
  const WeHearDocumantation2 = localStorage.getItem("WeHearDocumantation2");
  const user = JSON.parse(localStorage.getItem("user"));

  const deleteModule = (deleteModuleId) => {
    console.log(deleteModuleId);
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}module/delete?moduleId=${deleteModuleId}`,
      headers: {
        Authorization: `Bearer ${WeHearDocumantation1}`,
      },
    })
      .then((response) => {
        if (response.data) {
          console.log(response.data.data);
          setModules((current) =>
            current.filter((module) => module._id !== deleteModuleId)
          );
        }
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          const decodedJwt = JSON.parse(
            atob(WeHearDocumantation1.split(".")[1])
          );

          if (decodedJwt.exp * 1000 < Date.now()) {
            const newToken = getNewAccessToken(WeHearDocumantation2);
            if (newToken === "ERROR") {
              alert("Please login first");
              navigate("/sign-in");
            } else {
              localStorage.setItem("WeHearDocumantation1", newToken);
              alert("Token was expired! Please try again!");
              window.location.realod(false);
            }
          }
        } else alert(err.response.data.data[0].msg);
      });
  };

  const deleteSubModule = (subModule, id) => {
    axios({
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}module/update?moduleId=${id}&updateType=deleteSubModule`,
      headers: {
        Authorization: `Bearer ${WeHearDocumantation1}`,
      },
      data: {
        subModuleName: subModule.subModuleName,
        content: subModule.content,
      },
    })
      .then((response) => {
        alert("Submodule Delete Successfull");
        const updatedModules = modules.map((module) => {
          if (module._id === id) return response.data.data;
          else return module;
        });
        setModules(updatedModules);
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          const decodedJwt = JSON.parse(
            atob(WeHearDocumantation1.split(".")[1])
          );

          if (decodedJwt.exp * 1000 < Date.now()) {
            const newToken = getNewAccessToken(WeHearDocumantation2);
            if (newToken === "ERROR") {
              alert("Please login first");
              navigate("/sign-in");
            } else {
              localStorage.setItem("WeHearDocumantation1", newToken);
              alert("Token was expired! Please try again!");
              window.location.realod(false);
            }
          }
        } else alert(err.response.data.data[0].msg);
      });
  };

  const Module = ({ module }) => {
    const [open, setOpen] = useState(false);
    const [accessOpen, setAccessOpen] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [accessActiveModule, setAccessActiveModule] = useState();
    useEffect(() => {
      if (accessActiveModule) {
        setAccessOpen(true);
        setAccessActiveModule();
      }
    }, [accessActiveModule]);

    return (
      <div className="div">
        <h2 className="module_name" onClick={() => setOpen(!open)}>
          <span className="dropdown-toggle">{module.name}</span>
        </h2>
        {(user.role === "admin" || user.role === "teamLeader") && (
          <>
            <>
              <button
                onClick={() => {
                  setAccessActiveModule(module);
                }}
              >
                <i class="fa-solid fa-share"></i>
              </button>
              <button
                onClick={() => {
                  if (
                    window.confirm(
                      "Do want to delete module " + module.name + "?"
                    )
                  )
                    deleteModule(module._id);
                }}
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            </>

            {accessOpen && (
              <Access
                module={module}
                setAccessOpen={setAccessOpen}
                setClicked={setClicked}
              />
            )}
          </>
        )}
        {open &&
          module.subModules.map((subModule, index) => {
            return (
              <>
                <SubModules
                  subModule={subModule}
                  index={index}
                  id={module._id}
                />
              </>
            );
          })}
      </div>
    );
  };

  const SubModules = ({ subModule, index, id }) => {
    const {
      setContent,
      setActiveSubModuleName,
      setActiveModuleId,
      setSubModuleIndex,
      setSubModulePermittedUsers,
    } = useContext(SubModule);
    const [openAccessForSub, setOpenAccessForSub] = useState(false);
    const [activeSubModuleIndex, setActiveSubModuleIndex] = useState();
    const [subClicked, setSubClicked] = useState(false);
    useEffect(() => {
      if (activeSubModuleIndex !== undefined) {
        setOpenAccessForSub(true);
        setActiveSubModuleIndex();
        // console.log(openAccessForSub);
        console.log(activeSubModuleIndex);
      }
    }, [activeSubModuleIndex]);

    return (
      <>
        <div className="sub_module">
          <p
            key={subModule.subModuleName}
            onClick={() => {
              setActiveSubModuleName(subModule.subModuleName);
              setContent(subModule.content);
              setActiveModuleId(id);
              setSubModuleIndex(index);
              setSubModulePermittedUsers(subModule.editPremittedUsers);
            }}
          >
            <span className="span">{subModule.subModuleName}</span>
          </p>
          {(user.role === "admin" || user.role === "teamLeader") && (
            <>
              <button
                className="sub_module_access"
                onClick={() => {
                  setActiveSubModuleIndex(index);
                  // console.log(activeSubModuleIndex);
                  // openModal();
                }}
              >
                <i class="fa-solid fa-share"></i>
              </button>
              {openAccessForSub && (
                <AccessSub
                  editPremittedUsers={subModule.editPremittedUsers}
                  setAccessOpen={setOpenAccessForSub}
                  setClicked={setSubClicked}
                  index={index}
                  id={id}
                />
              )}
              <button
                className="delete_submodule"
                onClick={() => {
                  if (
                    window.confirm(
                      "Do want to delete submodule " +
                      subModule.subModuleName +
                      "?"
                    )
                  )
                    deleteSubModule(subModule, id);
                }}
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            </>
          )}
        </div>
      </>
    );
  };

  console.log(modules);

  return (
    <>
      {modules && (
        <div>
          {modules.map((module) => {
            return (
              <>
                <Module key={module.name} module={module} />
              </>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Initial;
