import React, { useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { getNewAccessToken, LoggedInUser } from "../App";

const SignUp = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(LoggedInUser);
  const [data, setData] = useState({
    name: "",
    email: "",
    role: "",
    team: "",
    password: "",
  });

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const WeHearDocumantation1 = localStorage.getItem("WeHearDocumantation1");
    const WeHearDocumantation2 = localStorage.getItem("WeHearDocumantation2");
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}user/create`,
      headers: {
        Authorization: `Bearer ${WeHearDocumantation1}`,
      },
      data: {
        name: data.name,
        email: data.email,
        password: data.password,
        role: data.role,
        team: data.team,
      },
    })
      .then((response) => {
        if (response.data) {
          alert("User added");
          navigateToUser();
        }
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          const decodedJwt = JSON.parse(
            atob(WeHearDocumantation1.split(".")[1])
          );

          if (decodedJwt.exp * 1000 < Date.now()) {
            const newToken = getNewAccessToken(WeHearDocumantation2);
            if (newToken === "ERROR") {
              alert("Please login first");
              navigate("/sign-in");
            } else {
              localStorage.setItem("WeHearDocumantation1", newToken);
              alert("Token was expired! Please try again!");
              window.location.realod(false);
            }
          }
        } else alert(err.response.data.data[0].msg);
        // if(err.data.data.isArray())
        // alert(err.data.data[0].msg);
        // else
        // alert(err.data.data.msg)
      });
  };

  const navigateToUser = () => {
    navigate("/user");
  };

  return (
    <>
      <div className="App">
        <div className="auth-wrapper">
          <div className="auth-inner">
            <nav className="navbar navbar-expand-lg navbar-light fixed-top">
              <div className="container">
                <button className="go_back" onClick={()=>{
                  navigate("/userList");
                }}>
                  <i class="fa-solid fa-circle-arrow-left"></i>
                </button>
                <Link className="navbar-brand" to={"/sign-in"}>
                  WeHear
                </Link>
              </div>
            </nav>
            <form onSubmit={handleSubmit}>
              <h3>Add User</h3>

              <div className="mb-3">
                <label>Name</label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  // value={data.name}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3">
                <label>Email address</label>
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Enter email"
                  // value={data.email}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3">
                <label>Role</label>
                <Form.Select
                  aria-label=" select example"
                  name="role"
                  onChange={handleChange}
                >
                  <option value={null}>Select Role...</option>
                  <option value="admin" name="admin">
                    admin
                  </option>
                  <option value="teamLeader" name="teamLeader">
                    teamLeader
                  </option>
                  <option value="employee" name="employee">
                    employee
                  </option>
                </Form.Select>
              </div>

              <div className="mb-3">
                <label>Team</label>
                <Form.Select
                  aria-label=" select example"
                  name="team"
                  onChange={handleChange}
                >
                  <option value={null}>Select Team...</option>
                  <option value="it" name="it">
                    it
                  </option>
                  <option value="ec" name="ec">
                    ec
                  </option>
                  <option value="all" name="all">
                    all
                  </option>
                </Form.Select>
              </div>

              <div className="mb-3">
                <label>Password</label>
                <input
                  name="password"
                  type="password"
                  className="form-control"
                  placeholder="Enter password"
                  // value={data.password}
                  onChange={handleChange}
                />
              </div>

              <div className="d-grid">
                <button type="submit" className="btn btn-primary">
                  Add User
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
