import { React, createContext, useState } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import User from "./components/User";
import Login from "./components/Login";
import SignUp from "./components/Sighup";
import UserList from "./components/UserList";
import axios from "axios";
export const LoggedInUser = createContext();
export const Modules = createContext();
export const SubModule = createContext();

export const getNewAccessToken = () => {
  axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}auth/resetToken`,
  })
    .then((response) => {
      if (response.data) {
        console.log(response.data.data);
        return response.data.data.accesToken;
      }
    })
    .catch((err) => {
      return "ERROR";
    });
};

function App() {
  const [user, setUser] = useState("");
  const [users, setUsers] = useState([]);
  const [modules, setModules] = useState([]);
  const [content, setContent] = useState("");
  const [subModulePermittedUsers, setSubModulePermittedUsers] = useState([]);
  const [activeSubModuleName, setActiveSubModuleName] = useState(
    "Select any submodule"
  );
  const [activeModuleId, setActiveModuleId] = useState();
  const [subModuleIndex, setSubModuleIndex] = useState();

  return (
    <LoggedInUser.Provider value={{ user, setUser, users, setUsers }}>
      <Modules.Provider value={{ modules, setModules }}>
        <SubModule.Provider
          value={{
            content,
            setContent,
            activeSubModuleName,
            setActiveSubModuleName,
            activeModuleId,
            setActiveModuleId,
            subModuleIndex,
            setSubModuleIndex,
            subModulePermittedUsers,
            setSubModulePermittedUsers,
          }}
        >
          <Router>
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route exact path="/sign-in" element={<Login />} />
              <Route exact path="/sign-up" element={<SignUp />} />
              <Route exact path="/user" element={<User />} />
              <Route exact path="/userList" element={<UserList />} />
            </Routes>
          </Router>
        </SubModule.Provider>
      </Modules.Provider>
    </LoggedInUser.Provider>
  );
}

export default App;
