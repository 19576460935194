import { useContext, useEffect, useState } from "react";
import Select, { components } from "react-select";
import { Modal, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getNewAccessToken, LoggedInUser, Modules, SubModule } from "../App";
import axios from "axios";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const prop = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={prop}
    >
      <input type="checkbox" checked={isSelected} />
      {children}
    </components.Option>
  );
};

export var selectedVals = [];
var accessedUsers = [];

export default function AccessSub(props) {
  const navigate = useNavigate();
  const WeHearDocumantation1 = localStorage.getItem("WeHearDocumantation1");
  const WeHearDocumantation2 = localStorage.getItem("WeHearDocumantation2");
  const { users } = useContext(LoggedInUser);
  const { modules, setModules } = useContext(Modules);
  const premittedUsers = props.editPremittedUsers;
  const [addAccess, setAddAccess] = useState(true);
  console.log("log1");
  const addAccessClose = () => setAddAccess(false);
  const allUser = [];
  for (let i = 0; i < users.length; i++) {
    allUser.push({ value: users[i]._id, label: users[i].name });
  }
  const initialUsersDatas = [];
  if (premittedUsers.length) {
    for (let i = 0; i < users.length; i++) {
      if (premittedUsers.includes(users[i]._id)) {
        initialUsersDatas.push({ value: users[i]._id, label: users[i].name });
      }
    }
  }

  const updateAccess = (id) => {
    console.log(accessedUsers);
    console.log(id);
    axios({
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}module/update?moduleId=${id}&updateType=updateSubModule`,
      headers: {
        Authorization: `Bearer ${WeHearDocumantation1}`,
      },
      data: {
        editPremittedUsers: accessedUsers,
        subModuleIndex: props.index,
      },
    })
      .then((response) => {
        alert("Sub module access updated");
        if (response.data) {
          console.log(response.data.data);
          const updatedModules = modules.map((module) => {
            if (module._id === id) return response.data.data;
            else return module;
          });
          setModules(updatedModules);
        }
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          const decodedJwt = JSON.parse(
            atob(WeHearDocumantation1.split(".")[1])
          );

          if (decodedJwt.exp * 1000 < Date.now()) {
            const newToken = getNewAccessToken(WeHearDocumantation2);
            if (newToken === "ERROR") {
              alert("Please login first");
              navigate("/sign-in");
            } else {
              localStorage.setItem("WeHearDocumantation1", newToken);
              alert("Token was expired! Please try again!");
              window.location.realod(false);
            }
          }
        } else alert(err.response.data.data[0].msg);
      });
  };

  return (
    <Modal
      show={addAccess}
      onHide={() => {
        addAccessClose();
        props.setAccessOpen(false);
        props.setClicked(false);
      }}
    >
      <Form onSubmit={(e) => e.preventDefault()}>
        <Modal.Header closeButton>
          <Modal.Title>Manage sub module Access</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="App">
            <Select
              defaultValue={initialUsersDatas}
              isMulti
              isClearable
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(options) => {
                if (Array.isArray(options)) {
                  accessedUsers = options.map((opt) => {
                    return opt.value;
                  });
                }
              }}
              options={allUser}
              components={{
                Option: InputOption,
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              addAccessClose();
              props.setAccessOpen(false);
              props.setClicked(false);
            }}
          >
            Close
          </Button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() => {
              console.log(props.id);
              updateAccess(props.id);
              addAccessClose();
              props.setAccessOpen(false);
              props.setClicked(false);
            }}
          >
            Add
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
