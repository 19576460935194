import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getNewAccessToken } from "../App";

const UserList = () => {
  const navigate = useNavigate();
  const WeHearDocumantation1 = localStorage.getItem("WeHearDocumantation1");
  const WeHearDocumantation2 = localStorage.getItem("WeHearDocumantation2");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (!localStorage.getItem("WeHearDocumantation1")) {
      alert("Please login first");
      navigate("/sign-in");
    } else {
      fetchUsers();
    }
  }, []);

  const fetchUsers = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}user/fetch?userFetchFor=display`,
      headers: {
        Authorization: `Bearer ${WeHearDocumantation1}`,
      },
    })
      .then((response) => {
        if (response.data) {
          setUsers(response.data.data);
        }
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          const decodedJwt = JSON.parse(
            atob(WeHearDocumantation1.split(".")[1])
          );

          if (decodedJwt.exp * 1000 < Date.now()) {
            const newToken = getNewAccessToken(WeHearDocumantation2);
            if (newToken === "ERROR") {
              alert("Please login first");
              navigate("/sign-in");
            } else {
              localStorage.setItem("WeHearDocumantation1", newToken);
              alert("Token was expired! Please try again!");
              window.location.realod(false);
            }
          }
        } else alert(err.response.data.data[0].msg);
      });
  };

  const deleteUser = (id, team) => {
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}user/delete?userId=${id}&team=${team}`,
      headers: {
        Authorization: `Bearer ${WeHearDocumantation1}`,
      },
    })
      .then((response) => {
        if (response.data) {
          alert("User delete");
          setUsers((current) => current.filter((user) => user._id !== id));
        }
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          const decodedJwt = JSON.parse(
            atob(WeHearDocumantation1.split(".")[1])
          );

          if (decodedJwt.exp * 1000 < Date.now()) {
            const newToken = getNewAccessToken(WeHearDocumantation2);
            if (newToken === "ERROR") {
              alert("Please login first");
              navigate("/sign-in");
            } else {
              localStorage.setItem("WeHearDocumantation1", newToken);
              alert("Token was expired! Please try again!");
              window.location.realod(false);
            }
          }
        } else alert(err.response.data.data[0].msg);
      });
  };

  const handleSearch = (searchString) => {
    if (searchString.length) {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}user/search?searchString=${searchString}`,
        headers: {
          Authorization: `Bearer ${WeHearDocumantation1}`,
        },
      })
        .then((response) => {
          if (response.data) {
            setUsers(response.data.data);
          }
        })
        .catch((err) => {
          if (err.response.data.code === 401) {
            const decodedJwt = JSON.parse(
              atob(WeHearDocumantation1.split(".")[1])
            );

            if (decodedJwt.exp * 1000 < Date.now()) {
              const newToken = getNewAccessToken(WeHearDocumantation2);
              if (newToken === "ERROR") {
                alert("Please login first");
                navigate("/sign-in");
              } else {
                localStorage.setItem("WeHearDocumantation1", newToken);
                alert("Token was expired! Please try again!");
                window.location.realod(false);
              }
            }
          } else alert(err.response.data.data[0].msg);
        });
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <button className="go_back" onClick={()=>{
            navigate("/user");
          }}>
            <i class="fa-solid fa-circle-arrow-left"></i>
          </button>
          <div className="navbar-brand">WeHear</div>
          <div className="navbar_search">
            <input
              type="text"
              name="nabar_search"
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search..."
            />
          </div>
          <div className="add_user">
            <button
              type="button"
              className="add_user_btn btn btn-primary btn-floating"
              onClick={() => {
                navigate("/sign-up");
              }}
            >
              +
            </button>
            <p className="add_document">Add User</p>
          </div>
        </div>
      </nav>

      <div className="user_list">
        {users.map((user) => (
          <>
            <div className="user_card">
              <div className="user_card_heading">
                <h3>{user.name}</h3>
              </div>
              <div className="user_card_content">
                <p>Role: {user.role}</p>
                <p>Team: {user.team}</p>
                <button
                  type="button"
                  className="btn btn-primary btn-floating"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Do want to delete user " + user.name + "?"
                      )
                    )
                      deleteUser(user._id, user.team);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default UserList;
