import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { LoggedInUser } from "../App";

const Login = () => {
  const { user, setUser } = useContext(LoggedInUser);
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(data);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}auth/login`,
      data: {
        email: data.email,
        password: data.password,
      },
    })
      .then((response) => {
        //   axios.defaults.headers.common["Authorization"] =
        //   `Bearer ${response.data.data.accessToken}`;
        //   console.log(axios.defaults.headers.common["Authorization"]);
        localStorage.setItem("WeHearDocumantation1", response.data.data.accessToken);
        localStorage.setItem("WeHearDocumantation2", response.data.data.refreshToken);
        localStorage.setItem("user", JSON.stringify(response.data.data));
        setUser(response.data.data);
        navigateToUser();
      })
      .catch((err) => {
        alert(err.response.data.data[0].msg);
      });
  };

  const navigate = useNavigate();

  const navigateToUser = () => {
    navigate("/user");
  };

  return (
    <>
      <div className="App">
        <div className="auth-wrapper">
          <div className="auth-inner">
            <nav className="navbar navbar-expand-lg navbar-light fixed-top">
              <div className="container">
                <Link className="navbar-brand" to={"/sign-in"}>
                  WeHear
                </Link>
              </div>
            </nav>
            <form onSubmit={handleSubmit}>
              <h3>Sign In</h3>

              <div className="mb-3">
                <label>Email address</label>
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Enter email"
                  // value={data.email}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3">
                <label>Password</label>
                <input
                  name="password"
                  type="password"
                  className="form-control"
                  placeholder="Enter password"
                  // value={data.password}
                  onChange={handleChange}
                />
              </div>

              <div className="d-grid">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
