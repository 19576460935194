import React from "react";
import parse from "html-react-parser";
import ReactMarkdown from "react-markdown";


function ViewMode(props) {
  return (
    <>
      <h1>{props.subModuleName}</h1>
      {/* {parse(props.content)} */}
      <ReactMarkdown children={props.content} />
    </>
  );
}

export default ViewMode;
