import axios from "axios";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getNewAccessToken, LoggedInUser, Modules, SubModule } from "../App";
import RichTextEditor from "./RichTextEditor";
import ViewMode from "./ViewMode";
// import { Parser } from "html-to-react";
//import { Remarkable } from "remarkable";
import { convert } from "html-to-markdown";
import MarkdownEditor from "./MarkdownEditor";

const Editor = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const {
    content,
    setContent,
    activeSubModuleName,
    activeModuleId,
    subModuleIndex,
    subModulePermittedUsers,
  } = useContext(SubModule);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const { modules, setModules } = useContext(Modules);
  const WeHearDocumantation1 = localStorage.getItem("WeHearDocumantation1");
  const WeHearDocumantation2 = localStorage.getItem("WeHearDocumantation2");
  const [edit, setEdit] = useState(false);

  const getValue = (value) => {
    setValue(value);
  };

  const toggelEdit = () => {
    setEdit(!edit);
  };

  // const parser = new Parser();

  const saveContent = () => {
    // const markdownText = parser.parse(value);
    //const md = new Remarkable();
    //const markdownText = md.render(value);
    const markdownText = convert(value);
    console.log(markdownText);
    console.log(activeSubModuleName);
    axios({
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}module/update?updateType=updateSubModule&moduleId=${activeModuleId}`,
      headers: {
        Authorization: `Bearer ${WeHearDocumantation1}`,
      },
      data: {
        content: markdownText,
        subModuleIndex: Number(subModuleIndex),
      },
    })
      .then((response) => {
        if (response.data) {
          alert("Submodule update");
          console.log(response.data.data);
          const updatedModules = modules.map((module) => {
            if (module._id === activeModuleId) return response.data.data;
            else return module;
          });
          setModules(updatedModules);
          setEdit(false);
          setContent(
            response.data.data.subModules[Number(subModuleIndex)].content
          );
          console.log(content);
        }
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          const decodedJwt = JSON.parse(
            atob(WeHearDocumantation1.split(".")[1])
          );

          if (decodedJwt.exp * 1000 < Date.now()) {
            const newToken = getNewAccessToken(WeHearDocumantation2);
            if (newToken === "ERROR") {
              alert("Please login first");
              navigate("/sign-in");
            } else {
              localStorage.setItem("WeHearDocumantation1", newToken);
              alert("Token was expired! Please try again!");
              window.location.realod(false);
            }
          }
        } else alert(err.response.data.data[0].msg);
      });
  };

  // const ShowContent = () => {
  //   let show = 0;
  //   if (edit && subModulePermittedUsers.includes(user._id)) show = 1;

  //   return (
  //     <>
  //       {!show && <ViewMode content={content} SubModule={activeModuleId} />}
  //       {show && <RichTextEditor initialValue={content} getValue={getValue} />}
  //     </>
  //   );
  // };

  console.log(subModulePermittedUsers + " " + user._id);

  return (
    <>
      <div className="card">
        <h5 className="card-header">
          {activeSubModuleName}
          {
            <button className="edit_title">
              {subModulePermittedUsers.includes(user._id) && !edit && (
                <i
                  className="fa-solid fa-file-pen"
                  onClick={() => toggelEdit()}
                ></i>
              )}
            </button>
          }
        </h5>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6" style={{ width: "100%", height: "100%" }}>
              {(!edit || !subModulePermittedUsers.includes(user._id)) && (
                <ViewMode content={content} SubModule={activeModuleId} />
              )}
              {subModulePermittedUsers.includes(user._id) && edit && (
                //<RichTextEditor initialValue={content} getValue={getValue} />
                <MarkdownEditor initialValue={content} getValue={getValue} />
              )}
              {/* <ShowContent /> */}
              <br />
            </div>
          </div>
          {edit && subModulePermittedUsers.includes(user._id) && (
            <button
              type="button"
              className="btn btn-primary save_button"
              onClick={() => {
                saveContent();
                toggelEdit();
              }}
            >
              Save
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Editor;
