import React, { createContext, useContext, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Lists from "./Lists";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { getNewAccessToken, LoggedInUser, Modules } from "../App";
import { useNavigate } from "react-router";
import axios from "axios";
import Editor from "./Editor";
import logo from "../Logo/WeHear_Logo.png";
import Selection from "./Selection";

export const SelectedValue = createContext();

const User = () => {
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const { modules, setModules } = useContext(Modules);
  const [selectedValue, setSelectedValue] = useState([]);
  const { users, setUsers } = useContext(LoggedInUser);
  const navigate = useNavigate();
  const WeHearDocumantation1 = localStorage.getItem("WeHearDocumantation1");
  const WeHearDocumantation2 = localStorage.getItem("WeHearDocumantation2");
  useEffect(() => {
    if (!localStorage.getItem("WeHearDocumantation1")) {
      alert("Please login first");
      navigate("/sign-in");
    } else {
      fetchModules();
      fetchUsers();
    }
  }, []);

  const [message, setMessage] = useState("");

  useEffect(() => {
    const now = new Date();
    const hour = now.getHours();

    if (hour >= 6 && hour < 12) {
      setMessage("Good morning");
    } else if (hour >= 12 && hour < 18) {
      setMessage("Good afternoon");
    } else {
      setMessage("Good evening");
    }

    const intervalId = setInterval(() => {
      const newNow = new Date();
      const newHour = newNow.getHours();

      if (newHour >= 6 && newHour < 12) {
        setMessage("Good morning");
      } else if (newHour >= 12 && newHour < 18) {
        setMessage("Good afternoon");
      } else {
        setMessage("Good evening");
      }
    }, 1000 * 60); // update message every minute

    return () => clearInterval(intervalId);
  }, []);

  const fetchUsers = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}user/fetch?userFetchFor=access`,
      headers: {
        Authorization: `Bearer ${WeHearDocumantation1}`,
      },
    })
      .then((response) => {
        if (response.data) setUsers(response.data.data);
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          const decodedJwt = JSON.parse(
            atob(WeHearDocumantation1.split(".")[1])
          );

          if (decodedJwt.exp * 1000 < Date.now()) {
            const newToken = getNewAccessToken(WeHearDocumantation2);
            if (newToken === "ERROR") {
              alert("Please login first");
              navigate("/sign-in");
            } else {
              localStorage.setItem("WeHearDocumantation1", newToken);
              alert("Token was expired! Please try again!");
              window.location.realod(false);
            }
          }
        } else alert(err.response.data.data[0].msg);
      });
  };

  const fetchModules = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}module/fetch`,
      headers: {
        Authorization: `Bearer ${WeHearDocumantation1}`,
      },
    })
      .then((response) => {
        if (response.data) {
          setModules(response.data.data);
        }
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          const decodedJwt = JSON.parse(
            atob(WeHearDocumantation1.split(".")[1])
          );

          if (decodedJwt.exp * 1000 < Date.now()) {
            const newToken = getNewAccessToken(WeHearDocumantation2);
            if (newToken === "ERROR") {
              alert("Please login first");
              navigate("/sign-in");
            } else {
              localStorage.setItem("WeHearDocumantation1", newToken);
              alert("Token was expired! Please try again!");
              window.location.realod(false);
            }
          }
        } else alert(err.response.data.data[0].msg);
      });
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [addShow, setAddShow] = useState(false);
  const addHandleClose = () => setAddShow(false);
  const addHandleShow = () => setAddShow(true);

  const [addModule, setAddModule] = useState({
    name: "",
    team: "",
  });

  const handleChange = (event) => {
    setAddModule({ ...addModule, [event.target.name]: event.target.value });
  };

  const handleAddModule = (event) => {
    event.preventDefault();
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}module/create`,
      headers: {
        Authorization: `Bearer ${WeHearDocumantation1}`,
      },
      data: {
        name: addModule.name,
        team: addModule.team,
        premittedUsers: selectedValue,
      },
    })
      .then((response) => {
        if (response.data) {
          alert("Module added");
          console.log(response.data.data);
          setModules([...modules, response.data.data]);
        }
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          const decodedJwt = JSON.parse(
            atob(WeHearDocumantation1.split(".")[1])
          );

          if (decodedJwt.exp * 1000 < Date.now()) {
            const newToken = getNewAccessToken(WeHearDocumantation2);
            if (newToken === "ERROR") {
              alert("Please login first");
              navigate("/sign-in");
            } else {
              localStorage.setItem("WeHearDocumantation1", newToken);
              alert("Token was expired! Please try again!");
              window.location.realod(false);
            }
          }
        } else alert(err.response.data.data[0].msg);
      });
  };

  const [addSubModule, setAddSubModule] = useState({
    team: "",
    name: "",
  });

  const logOutUser = () => {
    localStorage.clear();
    navigate("/");
  };

  const moduleHandleChange = (event) => {
    setAddSubModule({
      ...addSubModule,
      [event.target.name]: event.target.value,
    });
  };

  const moduleHandleSubmit = (event) => {
    event.preventDefault();
    console.log(addSubModule);
    axios({
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}module/update?updateType=addSubModule&moduleId=${addSubModule.moduleId}`,
      headers: {
        Authorization: `Bearer ${WeHearDocumantation1}`,
      },
      data: {
        subModuleName: addSubModule.name,
        content: "",
        editPremittedUsers: selectedValue,
      },
    })
      .then((response) => {
        if (response.data) {
          alert("Submodule added");
          const updatedModules = modules.map((module) => {
            if (module._id === addSubModule.moduleId) return response.data.data;
            else return module;
          });
          setModules(updatedModules);
        }
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          const decodedJwt = JSON.parse(
            atob(WeHearDocumantation1.split(".")[1])
          );

          if (decodedJwt.exp * 1000 < Date.now()) {
            const newToken = getNewAccessToken(WeHearDocumantation2);
            if (newToken === "ERROR") {
              alert("Please login first");
              navigate("/sign-in");
            } else {
              localStorage.setItem("WeHearDocumantation1", newToken);
              alert("Token was expired! Please try again!");
              window.location.realod(false);
            }
          }
        } else alert(err.response.data.data[0].msg);
      });
  };

  return (
    <>
      <SelectedValue.Provider value={{ selectedValue, setSelectedValue }}>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <div className="navbar-brand">WeHear</div>
            <div className="greeting">
              {" "}
              {message}, {localUser.name}
            </div>
            <NavDropdown className="actions" title="Actions" id="nav-dropdown">
              {(localUser.role === "admin" ||
                localUser.role === "teamLeader") && (
                  <>
                    <NavDropdown.Item
                      onClick={() => {
                        navigate("/userList");
                      }}
                    >
                      User List
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={addHandleShow}>
                      Add Module
                    </NavDropdown.Item>
                    <Modal show={addShow} onHide={addHandleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add Module</Modal.Title>
                      </Modal.Header>
                      <Form onSubmit={handleAddModule}>
                        <Modal.Body>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>Enter Name of New Module</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Module"
                              name="name"
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Select Team</Form.Label>
                            <div className="mb-3">
                              <Form.Select
                                aria-label=" select example"
                                name="team"
                                onChange={handleChange}
                              >
                                <option value={null}>Select Team...</option>
                                <option value="it" name="it">
                                  it
                                </option>
                                <option value="ec" name="ec">
                                  ec
                                </option>
                                <option value="all" name="all">
                                  all
                                </option>
                              </Form.Select>
                            </div>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Premitted Users</Form.Label>
                            <Selection users={users} user={localUser} />
                          </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={addHandleClose}>
                            Close
                          </Button>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={addHandleClose}
                          >
                            Add
                          </button>
                        </Modal.Footer>
                      </Form>
                    </Modal>
                  </>
                )}
              <NavDropdown.Item onClick={handleShow}>
                Add SubModule
              </NavDropdown.Item>
              <Modal show={show} onHide={handleClose}>
                <Form onSubmit={moduleHandleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title>Add Sub Module</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Select Module</Form.Label>
                      <div className="mb-3">
                        <Form.Select
                          aria-label=" select example"
                          name="moduleId"
                          onChange={moduleHandleChange}
                        >
                          <option value={null}>Select Module...</option>
                          {modules &&
                            modules.map((module) => {
                              return (
                                <>
                                  <option value={module._id}>
                                    {module.name}
                                  </option>
                                </>
                              );
                            })}
                        </Form.Select>
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Enter Name of New Sub Module</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Sub Module"
                        name="name"
                        onChange={moduleHandleChange}
                      />
                    </Form.Group>
                    {(localUser.role === "admin" ||
                      localUser.role === "teamLeader") && (
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Users with edit premission</Form.Label>
                          <Selection users={users} user={localUser} />
                        </Form.Group>)}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleClose}
                    >
                      Add
                    </button>
                  </Modal.Footer>
                </Form>
              </Modal>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => {
                  logOutUser();
                }}
              >
                Log-out
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </nav>

        <div className="main_document">
          <div className="drop_down_left">
            <nav className="vertical">
              <div
                style={{
                  marginTop: "45px",
                  borderTop: "1px solid lightgray",
                }}
              >
                <Lists />
              </div>
            </nav>
          </div>

          <div className="edit_document_right">
            <Editor />
          </div>
        </div>
      </SelectedValue.Provider>
    </>
  );
};

export default User;
