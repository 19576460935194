import React, { useContext, useState } from "react";
import Select from "react-select";
import { SelectedValue } from "./User";

const Selection = (props) => {
  const { selectedValue, setSelectedValue } = useContext(SelectedValue);

  const addHandleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const users = props.users;

  let Data = [];
  for (let i = 0; i < props.users.length; i++) {
    if(users[i]._id !== props.user._id)
    Data.push({
      value: users[i]._id,
      label: users[i].name,
    });
  }

  return (
    <>
      <Select
        className="dropdown"
        placeholder="Select Option"
        value={Data.filter((obj) => selectedValue.includes(obj.value))}
        options={Data}
        onChange={addHandleChange}
        name="premittedUsers"
        isMulti
        isClearable
      />
    </>
  );
};

export default Selection;
