import React from "react";
import Editor from "react-markdown-editor-lite";
import ReactMarkdown from "react-markdown";
import "react-markdown-editor-lite/lib/index.css";
import { firebaseStorage } from "../firebaseConfig";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

export default function MarkdownEditor({ initialValue, getValue }) {
  const mdEditor = React.useRef(null);
  const [value, setValue] = React.useState(initialValue);

  const handleEditorChange = ({ html, text }) => {
    const newValue = text;
    console.log(newValue);
    getValue(newValue);
    setValue(newValue);
  };

  function handleImageUpload(file){

    return new Promise(resolve => {
      const storageRef = ref(firebaseStorage, `files/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask
        .then((snapshot) => {
          getDownloadURL(storageRef)
            .then((url) => {
              console.log(url);
              const urlStr = url.toString();
              resolve(urlStr);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  return (
    <div className="App">
      <Editor
        ref={mdEditor}
        value={value}
        style={{
          height: "500px",
          textAlign: "left",
        }}
        onChange={handleEditorChange}
        renderHTML={(text) => <ReactMarkdown children={text} />}
        onImageUpload={handleImageUpload}
      />
    </div>
  );
}
