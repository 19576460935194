import React, { useState } from "react";
import { Modal, Button, Form } from 'react-bootstrap'
import Select from 'react-select';
import { SubModule } from "../App";
import Initial from "./Initial";


const Lists = (props) => {

    const { modules, setModules } = useState(props.modules);



    return (
        <>
            <Initial modules={props.modules}/>
        </>
    )
}

export default Lists;